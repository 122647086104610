import { html, property } from 'lit-element'
import { repeat } from 'lit-html/directives/repeat'
import globalSearchActions from './global-search-actions.styles.scss'
import { ShellElement } from '../../../common/shell-element'
import type { GlobalSearchActionUI } from '../global-search-models'
import { unsafeSVG } from 'lit-html/directives/unsafe-svg'
import { executeGlobalSearchCommand } from '../helpers'
import { trackGlobalSearch } from '../analytics/analytics'
import type { GlobalSearchResultItem } from '@goto/shell-common'

export interface GlobalSearchActionsProps<ResultItemDataType = unknown> {
  readonly actions: readonly GlobalSearchActionUI[]
  readonly originalResult?: GlobalSearchResultItem<ResultItemDataType> | undefined
  readonly searchString: string
}
export class GlobalSearchActions extends ShellElement implements GlobalSearchActionsProps {
  static readonly tagName = 'goto-global-search-actions'

  @property({ type: Array }) actions: GlobalSearchActionsProps['actions'] = []
  @property({ type: Object }) originalResult?: GlobalSearchActionsProps['originalResult']
  @property({ type: String }) searchString: GlobalSearchActionsProps['searchString'] = ''

  static get styles() {
    return globalSearchActions
  }

  renderAction(action: GlobalSearchActionUI) {
    return html`<chameleon-interactive-sub-item
      class="action-sub-item"
      label=${action.displayName}
      @click=${(e: UIEvent) => this.handleActionClick(e, action)}
    >
      <chameleon-round-button variant="secondary" aria-label=${action.displayName}
        ><chameleon-svg slot="end" class="left">${unsafeSVG(action.icon)}</chameleon-svg>
      </chameleon-round-button></chameleon-interactive-sub-item
    > `
  }

  private readonly handleActionClick = (e: UIEvent, action: GlobalSearchActionUI) => {
    const eventType = e.detail ? 'click' : 'keydown'
    const eventName = this.originalResult
      ? 'GoTo > Global Search Result Preview Action'
      : 'GoTo > Global Search No Result Action'
    trackGlobalSearch({
      searchString: this.searchString,
      eventName,
      eventType,
      action,
      searchResult: this.originalResult as GlobalSearchResultItem,
      properties: {},
    })
    executeGlobalSearchCommand(action.command, this.searchString, this.originalResult?.data)
  }

  render() {
    return html`
      ${repeat(
      this.actions,
      action => action.id,
      action => this.renderAction(action),
    )}
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly [GlobalSearchActions.tagName]: GlobalSearchActions
  }
}
