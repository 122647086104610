import { providerNameToServiceName } from './models'
import {
  type CalendarEventsResponse,
  type FetchClient,
  type CalendarConnectionResponse,
  type CalendarConnection,
  type ConnectedServiceType,
  type AuthorizatioUrl,
  type CalendarConnectResponse,
  type CalendarConnectorAPI,
  type GetEventsAPIOptions,
  type CalendarConnectorOpts,
} from './models'
import type { CISDateRange } from './date-utils'
import { getShellLogger } from '../../common/logger'
import { createUrlFromString, convertObjectToQueryString } from '../../common/url-helper'
import { getWindow } from '../../common/dom-helpers'
import { environment } from '../../environments'
import { authenticatedFetch } from '../auth'
import { assertsIsValidResponse, HttpResponseError } from '../../http-utils'
import { getShellApiInstance } from '../../common/shell-api-helpers'
import type { TypedResponse } from '../auth/authenticatedFetch'
import { getBrowserTimezone } from './helpers'
import { getFeatureFlagValue } from '../feature-flags'
import { FeatureFlagsVariations } from '../feature-flags/models'
import { encodeStringToBase64url } from '../../common'

export const callsCache = new Map<string, Promise<any>>()

export class CalendarIntegrationServiceConnector implements CalendarConnectorAPI {
  private readonly apiBaseUrl: string
  private readonly fetchClient: FetchClient

  constructor(
    params: CalendarConnectorOpts,

    fetchClient = getWindow().fetch,
  ) {
    const { apiBaseUrl = '' } = params
    if (apiBaseUrl === '') {
      throw new Error('apiBaseUrl must not be an empty string')
    }

    this.apiBaseUrl = apiBaseUrl
    this.fetchClient = fetchClient
  }

  async connect(...args: Parameters<CalendarConnectorAPI['connect']>) {
    const [externalUserKey, providerName, redirectUrl] = args
    let response: TypedResponse<CalendarConnectResponse> | undefined

    try {
      response = await this.fetchClient<CalendarConnectResponse>(this.getConnectionUrl(externalUserKey), {
        method: 'POST',
        body: JSON.stringify({ providerName, redirectUrl }),
        headers: [['Content-Type', 'application/json']],
      })
    } catch (e) {
      // silently fail
    }

    assertsIsValidResponse(response, 'calendar not connected')

    const { authorizationUrl = '' } = await response.json()

    return createUrlFromString(authorizationUrl).toString()
  }

  async disconnect(...args: Parameters<CalendarConnectorAPI['disconnect']>) {
    const [externalUserKey] = args
    let response: TypedResponse<void> | undefined

    try {
      response = await this.fetchClient<void>(this.getConnectionUrl(externalUserKey), {
        method: 'DELETE',
      })
    } catch (e) {
      // silently fail
    }

    assertsIsValidResponse<AuthorizatioUrl>(response, 'calendar not disconnected')
  }

  async getConnection(...args: Parameters<CalendarConnectorAPI['getConnection']>) {
    const [externalUserKey] = args
    let response: TypedResponse<CalendarConnection> | undefined

    try {
      response = await this.fetchClient<CalendarConnection>(this.getConnectionUrl(externalUserKey))
      // TODO handle 429 https://jira.ops.expertcity.com/browse/SCORE-1127
    } catch (e) {
      // silently fail
    }

    if (response?.status === 404) {
      throw new HttpResponseError({ ...response, response, message: 'no connection for account' })
    }

    assertsIsValidResponse(response, 'could not get calendar connection')

    const calendarConnection = await response.json()

    const calendarConnectionResponse: CalendarConnectionResponse = {
      ...calendarConnection,
      connected: true,
      connectedService: providerNameToServiceName[calendarConnection.providerSubType],
    }

    return calendarConnectionResponse
  }

  async isConnected(...args: Parameters<CalendarConnectorAPI['isConnected']>) {
    const [externalUserKey] = args
    //TODO: implement cacheing to reduce backend calls https://jira.ops.expertcity.com/browse/SCORE-1257
    let isConnected = false

    try {
      const response = await this.fetchClient(this.getConnectionUrl(externalUserKey))
      // TODO handle 429 https://jira.ops.expertcity.com/browse/SCORE-1127
      if (response.status === 200) {
        isConnected = true
      }
    } catch (e) {
      getShellLogger().warn('Calendar not connected: ', e)
    }

    return isConnected
  }

  async getConnectedServiceType(
    ...args: Parameters<CalendarConnectorAPI['getConnectedServiceType']>
  ): Promise<ConnectedServiceType | null> {
    try {
      const connection = await this.getConnection(...args)
      return connection.connectedService ?? null
    } catch (e) {
      getShellLogger().warn('Calendar not connected:', e)
    }
    return null
  }

  async getEvents(dateRange: CISDateRange, options?: GetEventsAPIOptions) {
    // This override is necessary to guarantee the cache completeness.
    // excludePrivate: needs to be false so we fetch all events.
    // tzid: we need it since we use the unix time (midnight) as key
    // to keep track of the days that have been fetched.

    const timezone = getBrowserTimezone()

    let optionsOverride: GetEventsAPIOptions = {
      excludePrivate: false,
      tzid: timezone,
    }

    if (getFeatureFlagValue(FeatureFlagsVariations.SHELL_CALENDAR_GETEVENTS_USE_ID)) {
      const notificationChannelId = getShellApiInstance().notificationChannel?.id ?? undefined
      optionsOverride = {
        ...optionsOverride,
        notificationChannelId,
      }
    } else {
      const { callbackURL } = getShellApiInstance().notificationChannel?.channelInfo ?? {}
      const notificationUrl = callbackURL ? encodeStringToBase64url(callbackURL) : undefined
      optionsOverride = {
        ...optionsOverride,
        notificationUrl,
      }
    }

    const { from, to } = dateRange.toCISdate()

    // "options" as defined in GetEventsAPIOptions is completely overriden,
    // but it will not be the case when the cache is removed.
    const formattedOptions = { ...options, ...optionsOverride }

    const formattedApiParams = {
      from,
      to,
      ...formattedOptions,
    }

    // https://artifactory.prodwest.citrixsaassbe.net/artifactory/documentation/g2m/calendar-integration-service/api/index.html#resources-events-list-events_ok
    const url = `${this.apiBaseUrl}/events?${convertObjectToQueryString(formattedApiParams)}`

    return await this.fetchResponse(url)
  }

  async getNextPage(...args: Parameters<CalendarConnectorAPI['getNextPage']>) {
    const [nextPageToken] = args
    const url = `${this.apiBaseUrl}/events/pages?nextPageToken=${nextPageToken}`
    return await this.fetchResponse(url)
  }

  private fetchClientWithCache<T>(requestUrl: string): Promise<TypedResponse<T>> {
    const cachedPromise = callsCache.get(requestUrl)
    if (cachedPromise) {
      return cachedPromise
    }

    const promise = this.fetchClient<T>(requestUrl).finally(() => {
      callsCache.delete(requestUrl)
    })

    callsCache.set(requestUrl, promise)
    return promise
  }

  private async fetchResponse(requestUrl: string): Promise<CalendarEventsResponse> {
    let response: TypedResponse<CalendarEventsResponse> | undefined

    try {
      response = await this.fetchClientWithCache<CalendarEventsResponse>(requestUrl)
    } catch (e) {
      // silently fail
    }
    // TODO handle 429 https://jira.ops.expertcity.com/browse/SCORE-1127

    assertsIsValidResponse(response, 'Could not get events')

    if (response.status === 204) {
      // slightly weird to throw an error when 204 is in the success range.
      throw new HttpResponseError({
        ...response,
        response,
        message: 'Calendar not connected',
      })
    }

    return await response.clone().json()
  }

  private getConnectionUrl(externalUserKey: string) {
    return `${this.apiBaseUrl}/users/${externalUserKey}/connection`
  }
}

let calendarConnector: CalendarConnector

/**
 * Returns a singleton instance of the CalendarIntegrationServiceConnector
 * @returns CalendarIntegrationServiceConnector
 */
export const getCalendarConnector = () => {
  if (!calendarConnector) {
    calendarConnector = new CalendarIntegrationServiceConnector(
      { apiBaseUrl: environment().calendarServiceBaseUrl },
      authenticatedFetch,
    )
  }

  return calendarConnector
}

export type CalendarConnector = CalendarIntegrationServiceConnector
