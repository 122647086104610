import { html, property } from 'lit-element'
import { ShellElement } from '../../common/shell-element'
import { t } from '../../directives/translate'
import { SVG_ADD_FILLED } from '@getgo/chameleon-icons'
import scheduleCustomHoursStyles from './schedule-custom-hours.styles.scss'

export class GoToScheduleCustomHours extends ShellElement {
  static readonly tagName = 'goto-schedule-custom-hours'

  @property({ type: Boolean }) isScheduleEnabled = false

  static get styles() {
    return scheduleCustomHoursStyles
  }

  render() {
    return html`
      <div class="custom-hours-header">
        <chameleon-typography class="small-header" variant="body-medium-strong"
          >${t('Custom hours')}</chameleon-typography
        >
        <div class="custom-hours-set">
          <chameleon-typography variant="body-small" color="type-color-secondary"
            >${t(
              'Add holidays, quiet hours, and other exceptions to your schedule availability.',
            )}</chameleon-typography
          ><chameleon-button
            class="add-custom-hours"
            variant="secondary"
            size="medium"
            ?disabled=${!this.isScheduleEnabled}
            ><chameleon-svg slot="start">${SVG_ADD_FILLED}</chameleon-svg>${t('Add custom hours')}</chameleon-button
          >
        </div>
      </div>
      <div class="custom-hours-schedule">CUSTOM HOURS GO HERE</div>
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly [GoToScheduleCustomHours.tagName]: GoToScheduleCustomHours
  }
}
