import { ShellElement } from '../../common/shell-element'
import { html } from 'lit-element'
import { t } from '../../directives/translate'
import scheduleWorkHoursStyles from './schedule-work-hours.styles.scss'

export class GoToScheduleWorkHours extends ShellElement {
  static readonly tagName = 'goto-schedule-work-hours'

  static get styles() {
    return scheduleWorkHoursStyles
  }

  //  @property({ type: Boolean }) isScheduleEnabled = false

  render() {
    return html`
      <div class="work-hours-header">
        <chameleon-typography class="small-header" variant="heading-small" tag="h3"
          >${t('Work hours')}</chameleon-typography
        >
        <chameleon-typography variant="body-small" color="type-color-secondary"
          >${t('Set your regular availability for the week.')}</chameleon-typography
        >
      </div>
      <div class="work-hours-schedule">WORK HOURS GO HERE</div>
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly [GoToScheduleWorkHours.tagName]: GoToScheduleWorkHours
  }
}
