import { html, LitElement, state } from 'lit-element'
import styles from './integration-disconnected-page.styles.scss'
import { DOWNLOAD_MS_TEAMS_PLUGIN_URL } from '../../core/integrations-helpers'
import { getFromLocalStorage, getLocationOrigin, openNewTabFor, setToLocalStorage } from '../../common/dom-helpers'
import { getTranslation } from '../../services/i18n/i18nUtils'
import { START_MS_TEAMS_PLUGIN_ROUTE } from '../../common/routes'
import { SVG_EXTERNAL_LINK_OUTLINED } from '@getgo/chameleon-icons'
import { t } from '../../directives/translate'
import { nothing } from 'lit-html'
import { getShellAnalytics, getShellApiInstance } from '../../common/shell-api-helpers'
import { getFeatureFlagValue } from '../../services/feature-flags'
import { FeatureFlagsVariations } from '../../services/feature-flags/models'
import { setUsePlugin } from '../../services/external-interface/utils'

export const CONTACT_GOTO = 'https://www.goto.com/learn-more'
export class IntegrationDisconnectedPage extends LitElement {
  static readonly tagName = 'goto-integration-disconnected-page'
  @state() private firstTimeUser = true

  static get styles() {
    return styles
  }

  connectedCallback(): void {
    super.connectedCallback()
    this.checkFirstTimeUserStatus()
  }

  private checkFirstTimeUserStatus() {
    const teamsVisitedStorageKey = 'teams-page-visited'
    if (getFromLocalStorage(teamsVisitedStorageKey) === 'true') {
      this.firstTimeUser = false
    } else {
      this.firstTimeUser = true
      setToLocalStorage(teamsVisitedStorageKey, 'true')
    }
  }

  private getTitleText() {
    return this.firstTimeUser
      ? getTranslation('Download the GoTo for Teams plugin')
      : getTranslation('Start the GoTo for Teams plugin')
  }

  private getDescriptionText() {
    return this.firstTimeUser
      ? getTranslation(
          "For calls to work in Microsoft Teams, you'll need to download and run the GoTo for Teams plugin on your desktop.",
        )
      : getTranslation('For calls to work in Microsoft Teams, you must run the GoTo for Teams plugin on your desktop.')
  }

  private renderContactText() {
    return this.firstTimeUser
      ? html`
          <chameleon-typography variant="body-large" class="contact-text"
            >${t(`You'll also need a GoTo Connect account to continue. Don't have one?`)}
            <chameleon-link id="contact" href=${CONTACT_GOTO} target="_blank" variant="body-large"
              >${t('Contact sales')}</chameleon-link
            ></chameleon-typography
          >
        `
      : nothing
  }

  private getPluginButtonText() {
    return this.firstTimeUser ? getTranslation('Download') : getTranslation('Start plugin')
  }

  private trackPluginButtonClicked() {
    getShellAnalytics().track('GoTo > Integration > Plugin Button', {
      action: 'Click',
      event: this.firstTimeUser ? 'First time user: Download plugin' : 'Returning user: Start plugin',
    })
  }

  private pluginButtonHandler() {
    this.trackPluginButtonClicked()
    this.firstTimeUser ? openNewTabFor(DOWNLOAD_MS_TEAMS_PLUGIN_URL) : openNewTabFor(this.getStartPluginUrl())
  }

  private getStartPluginUrl() {
    return new URL(START_MS_TEAMS_PLUGIN_ROUTE, getLocationOrigin()).href
  }

  private renderPluginButton() {
    return html`
      <chameleon-button size="xlarge" @click=${this.pluginButtonHandler}
        ><chameleon-svg slot="end" data-test="external-link"> ${SVG_EXTERNAL_LINK_OUTLINED} </chameleon-svg
        >${this.getPluginButtonText()}</chameleon-button
      >
    `
  }

  private getPluginLinkDescriptionText() {
    return this.firstTimeUser ? getTranslation('Already have the plugin?') : getTranslation('Plugin not starting?')
  }

  private getPluginLinkText() {
    return this.firstTimeUser ? getTranslation('Start plugin') : getTranslation('Download again')
  }

  private getLinkTrackingEventText() {
    return this.firstTimeUser ? 'First time user: Start plugin' : 'Returning user: Download again'
  }

  private trackPluginLinkClicked() {
    getShellAnalytics().track('GoTo > Integration > Plugin link', {
      action: 'Click',
      event: this.getLinkTrackingEventText(),
    })
  }

  private async login() {
    setUsePlugin(false)
    getShellApiInstance().auth.login()
  }

  private getSignInNoPluginSection() {
    if (getFeatureFlagValue(FeatureFlagsVariations.SHELL_MS_TEAM_NO_PLUGIN, false)) {
      return html` <chameleon-typography variant="body-large"
        >${'Without plugin'}
        <chameleon-link id="start" variant="body-large" @click=${this.login}
          >${'login'}</chameleon-link
        ></chameleon-typography
      >`
    }
    return null
  }

  render() {
    return html` <div class="integration-disconnected-container">
      <chameleon-typography variant="heading-large" class="container-title">
        ${this.getTitleText()}
      </chameleon-typography>
      <chameleon-typography variant="body-large" class="container-description">
        ${this.getDescriptionText()}
      </chameleon-typography>
      ${this.renderContactText()}${this.renderPluginButton()}
      <div class="user-action-container">
        <chameleon-typography variant="body-large"
          >${this.getPluginLinkDescriptionText()}
          <chameleon-link
            id="start"
            href="${this.firstTimeUser ? this.getStartPluginUrl() : DOWNLOAD_MS_TEAMS_PLUGIN_URL}"
            target="_blank"
            variant="body-large"
            @click=${this.trackPluginLinkClicked}
            >${this.getPluginLinkText()}</chameleon-link
          ></chameleon-typography
        >
        ${this.getSignInNoPluginSection()}
      </div>
    </div>`
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly [IntegrationDisconnectedPage.tagName]: IntegrationDisconnectedPage
  }
}
