import type { TextFieldComponent } from '@getgo/chameleon-web'
import { SVG_ALERT_OUTLINED, SVG_LOADER, SVG_JOIN_OUTLINED } from '@getgo/chameleon-icons'
import { html, query, state } from 'lit-element'
import { nothing } from 'lit-html'
import { unsafeSVG } from 'lit-html/directives/unsafe-svg'
import { launchMeeting, validateMeetingId } from '../../common/meeting-helpers'
import { ShellElement } from '../../common/shell-element'
import { t } from '../../directives/translate'
import styles from './meeting-id-input.styles.scss'
import { formatMeetingId, formatMeetingUrl } from './meeting-id-input-utils'

const COULD_BE_MEETING_URL_REGEXP =
  /^(https?:\/\/|)(www\.|)(meet\.goto\.com|gotomeet\.me|app\.goto\.com\/meeting|)\/?\/[/äöüÄÖÜßa-zA-Z_\-\d]+$/

const COULD_BECOME_MEETING_ID_REGEXP = /^\d{1,3}$|^\d{3}-?\d{0,3}$|^\d{3}-?\d{3}-?\d{0,3}$/

const TOO_LONG_FOR_MEETING_ID_REGEXP = /(^(\d{3})-?(\d{3})-?(\d{4,})$)/

export class GoToMeetingIdInput extends ShellElement {
  static readonly tagName = 'goto-meeting-id-input'

  @query('.meeting-id-input-field') meetingIdInputField: TextFieldComponent | undefined
  @state() private invalidMeetingId = false
  @state() private meetingLoading = false

  static get styles() {
    return styles
  }

  private isPossibleMeetingId(input: string) {
    return COULD_BECOME_MEETING_ID_REGEXP.test(input)
  }

  private isPossibleMeetingUrl(input: string) {
    return COULD_BE_MEETING_URL_REGEXP.test(input)
  }

  private isTooLongForMeetingId(value: string) {
    return TOO_LONG_FOR_MEETING_ID_REGEXP.test(value)
  }

  private formatInputDisplay(inputValue: string) {
    let newInputValue = inputValue
    if (this.isPossibleMeetingUrl(inputValue)) {
      newInputValue = formatMeetingUrl(inputValue)
    }
    if (this.isPossibleMeetingId(newInputValue)) {
      newInputValue = formatMeetingId(newInputValue)
    }
    if (this.isTooLongForMeetingId(newInputValue)) {
      return newInputValue.replace(/-/g, '')
    }

    return newInputValue
  }

  private handleMeetingIdInputButtonClick() {
    this.handleMeetingId()
  }

  private handleMeetingIdInputKeydown(e: KeyboardEvent) {
    if (e.key === 'Enter') {
      this.handleMeetingId()
    }
  }

  private async handleMeetingId() {
    if (!this.meetingIdInputField?.value) {
      this.invalidMeetingId = true
      return
    }
    const meetingId = await validateMeetingId(this.meetingIdInputField.value, 'shell-landing-page')
    if (meetingId) {
      this.meetingLoading = true
      this.invalidMeetingId = false
      launchMeeting(meetingId)
    } else {
      this.invalidMeetingId = true
    }
  }

  private handleInput(event: { target: HTMLInputElement }) {
    const inputValue = event.target.value
    if (this.meetingIdInputField) {
      this.meetingIdInputField.value = this.formatInputDisplay(inputValue)
    }
    this.invalidMeetingId = false
  }

  render() {
    return html`
      <div class="text-field-container">
        <chameleon-text-field
          class="meeting-id-input-field"
          placeholder="${t('Enter meeting name or ID')}"
          fieldsize="xlarge"
          fullwidth
          @keydown=${this.handleMeetingIdInputKeydown}
          @input=${this.handleInput}
          ?error=${this.invalidMeetingId}
          label=${t('Enter meeting name or ID')}
        >
          ${this.invalidMeetingId
            ? html` <chameleon-svg class="alert-icon" slot="end">${unsafeSVG(SVG_ALERT_OUTLINED)}</chameleon-svg>`
            : nothing}
          ${this.meetingLoading
            ? html`<chameleon-icon-button size="large" variant="primary" slot="end" label=${t('Meeting loading')}>
                <chameleon-svg class="loading-icon">${unsafeSVG(SVG_LOADER)}</chameleon-svg></chameleon-icon-button
              >`
            : html`
                <chameleon-icon-button
                  slot="end"
                  class="join-icon-button"
                  id="join-icon-button"
                  size="large"
                  variant="primary"
                  @click=${this.handleMeetingIdInputButtonClick}
                  label=${t('Join')}
                >
                  <chameleon-svg>${unsafeSVG(SVG_JOIN_OUTLINED)}</chameleon-svg>
                </chameleon-icon-button>
                <chameleon-tooltip-v3 position="bottom-center" trigger-id="join-icon-button">
                  ${t('Join')}</chameleon-tooltip-v3
                >
              `}
        </chameleon-text-field>
        ${this.invalidMeetingId
          ? html` <chameleon-typography class="invalid-id-message" variant="caption-medium" color="type-color-danger"
              >${t('A valid meeting name or ID is required')}</chameleon-typography
            >`
          : nothing}
      </div>
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly [GoToMeetingIdInput.tagName]: GoToMeetingIdInput
  }
}
