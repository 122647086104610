import { SVG_EXTERNAL_LINK_OUTLINED, SVG_INFO_OUTLINED } from '@getgo/chameleon-icons'
import { html, LitElement, state } from 'lit-element'
import { t } from '../../directives/translate'
import { getShellAnalytics, getShellApiInstance } from '../../common/shell-api-helpers'
import styles from './integration-connected-landing.styles.scss'
import { nothing } from 'lit-html'
import { unsafeSVG } from 'lit-html/directives/unsafe-svg'
import { getInflightRequest } from '../../services/auth/utils'
import { setUsePlugin } from '../../services/external-interface/utils'

export class IntegrationConnectedLanding extends LitElement {
  static readonly tagName = 'goto-integration-connected-landing'
  @state() private hasStartedSignIn = false

  static get styles() {
    return styles
  }

  private login() {
    this.hasStartedSignIn = true
    const inflightPathname = getInflightRequest() ?? '/'
    setUsePlugin(true)
    getShellApiInstance().auth.login({ inflightPathname, restartFlow: true })
  }

  private renderSignInStartedInfoMessage() {
    return this.hasStartedSignIn
      ? html`
          <div class="message-info-container">
            <chameleon-svg>${unsafeSVG(SVG_INFO_OUTLINED)}</chameleon-svg>
            <chameleon-typography variant="caption-medium">
              ${t("We've opened a browser tab for you to sign in.")}
            </chameleon-typography>
          </div>
        `
      : nothing
  }

  private handleSignInClick() {
    //Mixpanel; Integration Sign in button clicked
    getShellAnalytics().track('GoTo > Integration > Sign in', { action: 'click', event: 'sign in' })

    this.login()
  }

  private trackContactSalesClick() {
    //Mixpanel; Integration Sign in button clicked
    getShellAnalytics().track('GoTo > Integration > Contact sales', { action: 'click', event: 'contact sales' })
  }

  render() {
    return html` <div class="landing-container">
      <div class="landing-content">
        <chameleon-typography variant="heading-large" class="container-title">
          ${t('Tools to help you work together better')}
        </chameleon-typography>
        <chameleon-typography variant="body-large" class="container-description">
          ${t('Sign in to see your calls, messages, and more.')}
        </chameleon-typography>
        <chameleon-button id="sign-in-to-goto" variant="primary" size="xlarge" @click=${this.handleSignInClick}>
          <chameleon-svg slot="end">${SVG_EXTERNAL_LINK_OUTLINED}</chameleon-svg>${t('Sign in to GoTo')}
        </chameleon-button>
        ${this.renderSignInStartedInfoMessage()}
        <div class="container-learn-more">
          <chameleon-typography variant="body-large">${t("Don't have a GoTo Connect account?")}</chameleon-typography>
          <chameleon-link
            variant="body-large"
            appearance="primary"
            href="https://www.goto.com/learn-more"
            @click=${this.trackContactSalesClick}
            >${t('Contact sales')}</chameleon-link
          >
        </div>
      </div>
    </div>`
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly [IntegrationConnectedLanding.tagName]: IntegrationConnectedLanding
  }
}
